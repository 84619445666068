import React from 'react'

export default function TafsirComp() {
  return (
    <div className="bg-white p-5 rounded-md">
      <h1 className="text-3xl">Tafsir Surah Al-fatihah Ayat 1</h1>
      <hr className="my-5"/>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo accusamus voluptatibus ab corrupti, eum similique quae rem numquam nam voluptas veniam quis nesciunt repudiandae doloremque, dolorum excepturi nulla est quas!</p>
    </div>
  )
}
